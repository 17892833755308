import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import { graphql, Link } from 'gatsby'
import H2 from 'components/typography/h2'
import Button from 'components/button/button'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'

const RemovedEquipmentPage = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Equipment Removed | Hutson Inc</title>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>

      <HeroImage title='Equipment Removed' image={heroImage} />

      <Content>
        <H2 textAlign='center'>This equipment has been removed</H2>
        <Message>
          Looks like this piece of equipment has been sold and removed from our site. We're
          constantly adding used equipment to our site. Visit our{' '}
          <Link to='/used-equipment/'>used equipment page</Link> to see our current inventory.
        </Message>
        <Button as={StyledLink} to='/used-equipment/'>
          View inventory
        </Button>
      </Content>
    </Layout>
  )
}

const Message = styled.p`
  margin: 0 auto 15px;
  max-width: 600px;
  text-align: center;

  @media (min-width: 800px) {
    margin: 0 auto 30px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  margin: 0 auto;
  max-width: 250px;
  text-decoration: none;
  width: 100%;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "used-equipment-single-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default RemovedEquipmentPage
